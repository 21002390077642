<template>
  <PWInput
    ref="input"
    v-model.trim="phoneNumberBodyMasked"
    type="text"
    inputmode="tel"
    :placeholder="placeholder"
    v-bind="{...$attrs, ...$props}"
    :disabled="disabled"
    @keypress="onlyPhoneNumber"
    @input="handleInput"
    @paste="handlePaste"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
import { parsePhoneNumber } from 'libphonenumber-js/min'
import PWInput from '~/components/_ui/PWInput'

const shortcutsMap = {
  UA: ['+380', '380', '0'],
  // Item could be arrayed, if array provided - [search shortcut, replace shortcut]
  RU: ['+7', '7', ['89', '8'], ['90', ''], ['91', ''], ['92', ''], ['93', ''], ['94', ''], ['95', ''], ['96', ''], ['97', ''], ['98', '']],
  KZ: ['+77', '77', '+76', '76', '87', ['86', '8']],
  BY: ['+375', '375', '80']
}

const PATTERNS = {
  UA: '+380 99 999 99 99',
  RU: '+7 999 999 99 99',
  KZ: '+7 799 999 99 99',
  BY: '+375 99 999 99 99'
}

export default {
  name: 'CMPhoneField',
  components: {
    PWInput
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Введите номер телефона'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'blur', 'focus'],
  data: () => ({
    inputMask: null,
    phoneNumberBodyMasked: '',
    currentCountryCode: null,

    loadCountriesPromise: null,

    countryCodes: []
  }),
  computed: {
    phoneNumberBody () {
      if (PATTERNS[this.currentCountry?.code]) {
        return this.inputMask.unmask(this.phoneNumberBodyMasked, {
          mask: PATTERNS[this.currentCountry.code]
        })
      }

      return this.phoneNumberBodyMasked
    },
    phoneNumberFull () {
      if (PATTERNS[this.currentCountry?.code]) {
        return (this.currentCountry?.dial_code || '') + this.phoneNumberBody
      }

      return this.phoneNumberBody
    },
    currentCountry () {
      return this.countryCodes.find(country => country.code === this.currentCountryCode)
    }
  },
  watch: {
    modelValue (value) {
      if (value !== this.phoneNumberFull) {
        this.setPhone(value)
      }
    }
  },
  async mounted () {
    this.inputMask = (await import('inputmask')).default
    await this.loadCountries()

    await this.setPhone(this.modelValue)
  },
  methods: {
    focus () { // ref method
      this.$refs.input.focus()
    },
    async setPhone (phoneNumber) {
      await this.loadCountriesPromise

      const parsedPhoneNumber = this.tryParsePhoneNumber(phoneNumber)

      this.currentCountryCode = parsedPhoneNumber?.country
      this.replaceMask(this.currentCountryCode)
      this.phoneNumberBodyMasked = phoneNumber
    },
    tryParsePhoneNumber (phoneNumber) {
      let parsedPhoneNumber
      try {
        parsedPhoneNumber = parsePhoneNumber(phoneNumber)
      } catch (e) { }

      return parsedPhoneNumber
    },
    async loadCountries () {
      const codes = await fetch('/country-codes.json')
        .then(res => res.json())

      this.countryCodes = codes
      // if (this.$store.state.users.countryCodes.length === 0) {
      //   await fetch('/country-codes.json')
      //   await (this.loadCountriesPromise = this.$store.dispatch('users/fetchCountryCodes'))
      // } else {
      //   this.loadCountriesPromise = Promise.resolve()
      // }
    },
    replaceMask (countryCode) {
      if (process.server) {
        return
      }
      const inputEl = this.$refs.input.$el.querySelector('input')

      const mask = PATTERNS[countryCode]
      if (mask) {
        const oldPhoneBody = this.phoneNumberBody
        this.inputMask({
          mask
        }).mask(inputEl)

        this.phoneNumberBodyMasked = oldPhoneBody
      } else {
        inputEl.inputmask?.remove()
        this.inputMask.remove(inputEl)
        inputEl.placeholder = ''
        this.phoneNumberBodyMasked = ''
      }
    },
    async handleInput () {
      await this.predictCountry()
      this.$emit('update:modelValue', this.phoneNumberFull)
    },
    handlePaste (e) {
      e.preventDefault()
      const clipboardData = e.clipboardData || window.clipboardData
      const pastedText = clipboardData.getData('Text')

      if (this.tryParsePhoneNumber(pastedText)) {
        this.setPhone(pastedText)
        this.$nextTick(() => this.$emit('update:modelValue', this.phoneNumberFull))
        return
      }

      setTimeout(() => {
        const onlyDigits = pastedText.replace(/[^\d.]/g, '')
        this.phoneNumberBodyMasked = onlyDigits
        this.handleInput()
      }, 0)
    },
    async predictCountry () {
      if (this.phoneNumberBody.length === 0) {
        await this.$nextTick()
        this.currentCountryCode = null
        this.replaceMask()
        return
      }

      if (this.currentCountryCode) {
        return
      }

      for (const [countryCode, shortcuts] of Object.entries(shortcutsMap)) {
        const shortcutTuple = shortcuts.find((shortcut) => {
          const searchShortcut = Array.isArray(shortcut) ? shortcut[0] : shortcut

          return this.phoneNumberBody.startsWith(searchShortcut)
        })

        if (shortcutTuple) {
          const replaceShortcut = Array.isArray(shortcutTuple) ? shortcutTuple[1] : shortcutTuple

          await this.$nextTick()
          this.phoneNumberBodyMasked = this.phoneNumberBodyMasked.replace(replaceShortcut, '')
          this.currentCountryCode = countryCode
          await this.$nextTick()
          this.replaceMask(countryCode)
          break
        }
      }
    },
    onlyPhoneNumber (e) {
      const charCode = e.keyCode
      if (charCode !== 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>
